import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from "../helpers/services";
import {Auth} from "aws-amplify";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public groups = this.authenticationService.currentUserValue.groups;

  constructor(private router: Router,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
  this.changeEmail();
  }
  //coding changing email to send for verification code
  //this changes all users email to joe.field@blackhorsesolutions.com
  async changeEmail() {
    let user = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(user, {
      'email': 'joe.field@blackhorsesolutions.com'
    });
    user.autoVerifyEmail = true;
    console.log(result);
  }

}
