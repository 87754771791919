export class Group {
  name: string;
  type: string;
  currentLoc: string;
  dob: string;
  pob: string;
  checkedOut: boolean;
  gender: string;
}

export interface IGroupResponse{
  total:number;
  results: Group[];
}
