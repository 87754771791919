import {Component, OnDestroy, OnInit} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute, Router} from "@angular/router";
import {PersonasInfoFULL} from "../helpers/objects/personasInfoFULL";
import {AuthenticationService} from "../helpers/services";
import {Storage} from 'aws-amplify';
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import {MatTableDataSource} from "@angular/material";
import {FormControl} from '@angular/forms';
import {bool} from 'aws-sdk/clients/signer';
import {MatSnackBar} from '@angular/material/';

//creating the account element and its fields
export class AccountElement{
  domain: string;
  username: string;
  password: string;

  //building the accountElem
  constructor(domain: string, username: string, password: string){
    this.domain = domain;
    this.username= username;
    this.password = password;
  }
}

//creating the notes and its fields
export class QuickNotes{
  title: string;
  content: string;

  //building the notes
  constructor(title: string, content: string){
    this.title = title;
    this.content = content;
  }
}

@Component({
  selector: 'app-persona-page',
  templateUrl: './persona-page.component.html',
  styleUrls: ['./persona-page.component.scss']
})
export class PersonaPageComponent implements OnInit, OnDestroy {
  PERSONA_API = 'chameleonDBAPI';
  accounts: AccountElement[] = [];
  public name: string;
  public username = this.authenticationService.currentUserValue.username;
  public personaInfo: PersonasInfoFULL = null;
  public group = this.authenticationService.currentUserValue.groups;
  private sub: any;
  //table
  dataSource: any;
  displayedColumns: string[] = ['domain' ,'username' , 'password'];
  allDataFetched: boolean = false;
  //notes
  titleModel: string;
  contentModel: string;
  quickNotes: QuickNotes[]=[];
  newNote: string;
  addingNote: boolean = false;


  selected = new FormControl(0);
  fileLog: boolean = false;


  constructor(private amplifyService: AmplifyService,
              private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar) {
    console.log(this.router.getCurrentNavigation().extras == 'logs', 'asdfa');
    if (this.router.getCurrentNavigation().extras == 'logs') {
      this.fileLog = true;
    }

    //making the fields empty
    this.titleModel='';
    this.contentModel = '';


    this.sub = this.route.params.subscribe(params => {
      this.name = params['persona_name'];
      // In a real app: dispatch action to load the details here.
    });

    this.getPersonInfo()
      .then(result => {this.initProfilePic();})
      .then( result => {this.loadAccounts();})
      .then( result => {

        this.allDataFetched = true;

        if (this.fileLog)
          this.selected.setValue(1);
        console.log(this.personaInfo);
      })
      .catch(err => console.log('could not initialize person'));

    //loading past notes
    this.loadNotes();


  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  clickProfilePic() {
    document.getElementById('pic-upload').click();
  }

  uploadProfilePic(pic) {
    if (pic) {
      let fileName = this.name.replace(/ /g, '') + '/profilepic.png';
      Storage.put(fileName, pic.files[0])
        .then(result => {
          document.getElementById("persona-picture").remove();
          this.initProfilePic();
        })
        .catch(err => console.log(err));
    }
  }

  initProfilePic() {
    let folderName = this.name.replace(/ /g, '');
    let hasProfilePic: boolean = false;

    Storage.list(folderName)
      .then(result => {
        for (let entry of result) {
          if (String(entry.key).includes('profilepic.png')) {
            hasProfilePic = true;
          }
        }
      })
      .then(result => {
        //If there is a valid picture stored in the S3, pull it and set it
        let image = document.createElement("img");
        let imageParent = document.getElementById("pic-container");
        image.id = "persona-picture";
        image.style.width = '100%';
        image.onmouseover = function () {
          image.style.opacity = '.75'
        };
        image.onmouseout = function () {
          image.style.opacity = '1'
        };

        if (hasProfilePic) {
          let fileName = folderName + '/profilepic.png';
          Storage.get(fileName)
            .then(result => {
              //If there is a valid picture stored in the S3, pull it and set it
              image.src = String(result);
              imageParent.appendChild(image);
            });
        } else {
          Storage.get('default_profile_pic.png')
            .then(result => {
              image.src = String(result);
              imageParent.appendChild(image);
            });
        }
      })
      .catch(err => console.log(err));
  }

  //sending checkin/out logs to the log
  addCheckInOutLog(inputName: string, checkingOut: boolean, persona: []) {
    if (checkingOut) {
      persona['logs'].push({
        'user': inputName,
        'type': 'Checking Out', 'dateTime': this.getDate()
      });
    } else {
      persona['logs'].push({
        'user': inputName,
        'type': 'Checking In', 'dateTime': this.getDate()
      });
    }

    return persona;
  }

  getDate() {
    let d = new Date();
    //Getting Date in consistent padding format
    let datestring = d.getFullYear() + "." +
      ("0" + (d.getMonth() + 1)).slice(-2) + "." +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2) + ":" +
      ("0" + d.getSeconds()).slice(-2);

    return datestring;
  }


  //this calls the database and gets the personas information
  //due to using formfields the form arrays are not able to get information for accounts and logs
  async getPersonInfo(): Promise<PersonasInfoFULL> {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === this.name) {
            let persona = new PersonasInfoFULL();
            persona['name'] = ppl['name'];
            persona['type'] = ppl['type'];
            persona['currentLoc'] = ppl['currentLoc'];
            persona['mailingAdd'] = ppl['mailingAdd'];
            persona['dob'] = ppl['dob'];
            persona['pob'] = ppl['pob'];
            persona['phone'] = ppl['phone'];
            persona['pin'] = ppl['pin'];
            persona['modelNum'] = ppl['modelNum'];
            persona['serialNum'] = ppl['serialNum'];
            persona['phoneNetwork'] = ppl['phoneNetwork'];
            persona['imei'] = ppl['imei'];
            persona['gen'] = ppl['gender'];
            persona['hs'] = ppl['hs'];
            persona['college'] = ppl['college'];
            persona['work'] = ppl['work'];
            persona['hobbies'] = ppl['hobbies'];
            persona['checkedOut'] = ppl['checkedOut'];
            persona['regions'] = ppl['regions'];
            persona['user'] = ppl['user'];

            this.personaInfo = persona;
          }
        }
      }
    } catch (error) {
      console.log("error");
    }
    return this.personaInfo;
  }


  //getting the accounts for the persona
  async loadAccounts() {
    this.accounts = [];
    let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
    if (response) {
      for (let ppl of response) {
        if (ppl['name'] === this.name) {
          for (let entry of ppl.accounts) {
            let singleAccount = entry;
            //pushing accounts to local array
            this.accounts.push(new AccountElement(
              singleAccount['domain'],
              singleAccount['username'],
              singleAccount['password']
            ));
          }
        }
      }
    }
    //saving accounts to a table
    this.dataSource = new MatTableDataSource<AccountElement>(this.accounts);
  }

  //loading notes
  async loadNotes() {
    this.quickNotes = [];
    let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
    if (response) {
      for (let ppl of response) {
        if (ppl['name'] === this.name) {
          if (ppl.notes == undefined)
            break;
          for (let entry of ppl['notes']) {
            let singleNote = entry;
            //pushing notes to local array
            this.quickNotes.push(new QuickNotes(
              singleNote['title'],
              singleNote['content']
            ));
          }
        }
      }
    }
  }

  async checkInOrOut(stat, name) {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === name) {
            ppl['checkedOut'] = !stat;
            let newPersona = ppl;
            newPersona = this.addCheckInOutLog(this.username, ppl['checkedOut'], ppl);
            const init = {
              body: newPersona
            };

            if(this.personaInfo['name'] === name){
              this.personaInfo['checkedOut'] = !this.personaInfo['checkedOut'];
            }

            //saving updated checkin/out to DB
            this.amplifyService.api().post(this.PERSONA_API, '/persona', init).then(result => {
              if (stat == false) { //alerts the user
                alert("You have now checked out " + name.split('_')[1] + ", please remember to return it when you are done with the session.");
              } else {
                let ischecked = confirm("You have now returned " + name.split('_')[1] + ", would you like to upload your session media, notes, and/or logs?");
                if (ischecked == true) {
                  this.selected.setValue(1);
                }
              }
            });

          }
        }
      }
    } catch
      (error) {
      console.log("error");
    }
    //this.ngOnInit();
    return;
  }

  //sends to a different page of the persona requested for edit
  onEdit(selectedPerson) {
    this.router.navigate(['/personas/edit', selectedPerson]);
  }

  //this method allows users to download a pdf of the personas information.
  public captureScreen() {
    console.log(name);
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      //var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      //var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(this.name.split('_')[1] + '.pdf'); // Generated PDF
    });
  }

  onAddNoteButton() {
    this.addingNote = true;
  }

  async createQuicknotes(){
    //storing the already created notes to array
    const newQuicknotes: QuickNotes = {
      title: this.titleModel,
      content: this.contentModel
    };
    for(let i of this.quickNotes){
      if(i.title === this.titleModel){
        alert('Notes can not have the same title');
        return;
      }
    }
    this.quickNotes.push(newQuicknotes);
    //this.titleModel = this.contentModel = '';

    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      console.log(response);
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === this.name) {
            console.log('ppl');
            let newPersona = ppl;
            newPersona['notes'] = this.quickNotes;
            const init = {
              body: newPersona
            };

            this.amplifyService.api().post(this.PERSONA_API, '/persona', init).then(result => {
              this.snackBar.open(`${this.titleModel} has been saved.`, 'Close', {duration: 1000});
            });

          }
        }
      }
    } catch
      (error) {
      console.log("error");
    }
    this.titleModel = this.contentModel = '';
    this.addingNote = false;
  }

  //delete notes
  async deleteNote(title:string){
    //console.log(title);
    for(let i of this.quickNotes){
      if(i.title === title){
        let index = this.quickNotes.indexOf(i);
        //console.log(index);
        this.quickNotes.splice(index, 1);
      }
    }
    //console.log(this.quickNotes);
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      //console.log(response);
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === this.name) {
            //console.log('ppl');
            let newPersona = ppl;
            newPersona['notes'] = this.quickNotes;
            const init = {
              body: newPersona
            };

            this.amplifyService.api().post(this.PERSONA_API, '/persona', init).then(result => {
              this.snackBar.open(`${title} has been deleted`, 'Close', {duration: 1000});
            });

          }
        }
      }
    } catch
      (error) {
      //console.log("error");
    }

  }

  //allows user to edit notes upon click
  editNote(event, note: QuickNotes){
    let index = this.quickNotes.indexOf(note);
    console.log(event.target.outerText, index);

    this.newNote = event.target.outerText;
    //console.log(this.newNote);
  }

  //saving the edits in the already existing notes
  async saveMyEdit(title: string){
    for(let n of this.quickNotes){
      if(n.title === title){
        n.content = this.newNote;
      }
    }
    //console.log(this.quickNotes);

    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/persona');
      //console.log(response);
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === this.name) {
            //console.log('ppl');
            let newPersona = ppl;
            newPersona['notes'] = this.quickNotes;
            const init = {
              body: newPersona
            };

            this.amplifyService.api().post(this.PERSONA_API, '/persona', init).then(result => {
              this.snackBar.open(`${title} has been saved`, 'Close', {duration: 1000});
            });

          }
        }
      }
    } catch
      (error) {
      //console.log("error");
    }

  }

}



