export class MDN {
    id: string;
    countryCallingCode: string;
    areaCode: string;
    subscriber: string;
    vendor: string;
    vendorStatus: string;
    since: Date;
    until?: Date;
    alwaysOn?: boolean;
    voicemail?: boolean;
    carrier: string;
    country: string;
    countryCode: string;
    region: string;
    regionCode: string;
    location: string;
    status: string;
}
