import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import {User} from '../helpers/objects';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from "../helpers/services";
import {NavigationEnd, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AmplifyService} from 'aws-amplify-angular';
import {MatSnackBar} from '@angular/material';
import {QuillConfigInterface} from 'ngx-quill-wrapper';
import 'rxjs/add/operator/pairwise';

export interface DialogData {
  title: string;
  body: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {

  PERSONA_API = 'chameleonDBAPI';
  addPath = '/alerts';
  currentUser: User;
  currentUserSubscription: Subscription;
  signedIn = false;
  region: string;
  alerts: object[] = [];
  alertsSeenByUser: number = 0;
  alertsUploaded: number = 0;
  newAlerts: number = 0;
  newestAlerts: number = 0;
  public groups: string[];

  constructor(private breakpointObserver: BreakpointObserver,
              private authService: AuthenticationService,
              private modalService: NgbModal,
              private router: Router,
              private amplifyService: AmplifyService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      if (user) {
        this.signedIn = true;
        this.currentUser = user;
        this.groups = user.groups;
      } else {
        this.currentUser = null;
        this.groups = null;
        this.router.navigate(['/login']);
      }
    });

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        if (this.router.url !== '/login' && this.router.url !== '/') {
          this.setRegion();
        }


        Promise.all([this.checkNumberOfAlertsUploaded(), this.checkNumberOfAlertsSeen()])
          .then(r => {
            this.newAlerts = this.alertsUploaded - this.alertsSeenByUser;
            this.newestAlerts = this.newAlerts;
            console.log(this.alertsUploaded, this.alertsSeenByUser, this.newAlerts);
          });
      }
    });

  }

  ngOnInit() {
  }

  setRegion() {
    this.region = localStorage.getItem('region');
  }

  onClickSignOut() {
    sessionStorage.clear();
    this.authService.logout();
  }

  onAlert() {
    console.log('Alerts Load');
    this.getAllAlerts()
      .then(r => {
        console.log(this.alerts)
      });
  }

  async checkNumberOfAlertsUploaded() {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/alerts');
      if (response) {
        this.alertsUploaded = response.length;
      }
    }catch (error) {
      console.log("error");
    }
  }

  async checkNumberOfAlertsSeen() {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/alertsHistory');
      if (response) {
        let found = false;
        for (let entry of response) {
          if (entry.name == localStorage.getItem('username')) {

            console.log('number uploadedd', this.alertsUploaded);
            console.log('last seen number', entry.lastSeenNumber);
            found = true;
            this.alertsSeenByUser = entry.lastSeenNumber;
          }
        }
        if (!found)
          this.alertsSeenByUser = 0;
      }
    }catch (error) {
      console.log("error");
    }
  }

  async getAllAlerts() {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, this.addPath);
      if (response) {
        this.alerts = response;
        this.alerts.sort((left, right): number => {
          if (left['title'] > right['title']) {
            return -1;
          }
          if (left['title'] < right['title']) {
            return 1;
          }
          return 0;
        });
      }
    }catch (error) {
      console.log("error");
    }

    this.updateAlertHistory();
  }

  async updateAlertHistory() {
    this.alertsSeenByUser = this.alertsUploaded;
    this.newAlerts = 0;

    let alert = { name: localStorage.getItem('username'), lastSeenNumber: this.alertsUploaded };

    const init = {
      body: alert
    };

    this.amplifyService.api().post(this.PERSONA_API, '/alertsHistory', init)
      .then(result => {
        console.log('alert hist changed')
      })
      .catch(result => console.log(result));
  }

  onClickAlert(alertTitle, alertBody): void {
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '700px',
      data: {title: alertTitle, body: alertBody}
    });
  }

  onClickAddAlert(): void {
    const dialogRef = this.dialog.open(AddAlertDialog, {
      width: '700px',
      data: {number: this.alerts.length}
    });
  }
}

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert.dialog.html',
})
export class AlertDialog {

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public inputAlert: DialogData) {}


  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'add-alert-dialog',
  templateUrl: './add.alert.dialog.html',
})
export class AddAlertDialog {

  PERSONA_API = 'chameleonDBAPI';
  path = '/alerts';
  alerts: object[] = [];
  alertTitle: string = '';
  alertBody: string = '';

  ALERT_QUILL_CONFIG: QuillConfigInterface = {
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        ['link', 'image'],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
      ]
    },
    placeholder: 'Alert Body'
  };


  constructor(
    public dialogRef: MatDialogRef<AddAlertDialog>,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public numberOfAlerts,
    private modalService: NgbModal,
    private router: Router,
    private amplifyService: AmplifyService,
    public snackBar: MatSnackBar) {}

  async getAllAlerts() {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/alerts');
      if (response) {
        this.alerts = response;
      }
    }catch (error) {
      console.log("error");
    }
  }

  onSumbit() {
    let date: string = this.getDate();


    console.log(this.alertTitle, this.alertBody, this.alerts.length);
    if (this.alertTitle == '' || this.alertBody == '') {
      alert('Please fill out both the title and the body');
      return;
    }

    if(!confirm('Are you sure you want to submit this alert? It will be delivered to all users.'))
      return;

    this.getAllAlerts()
      .then( r=> {

        console.log(this.alertTitle, this.alertBody, this.alerts.length);

        let alert = { title: `${date}_${this.alertTitle}`, number: Object.keys(this.alerts).length + 1,
          body: this.alertBody , date: date };

        const init = {
          body: alert
        };

        this.amplifyService.api().post(this.PERSONA_API, '/alerts', init)
          .then(result => {
            this.snackBar.open(`"${alert.title}" has been saved`, 'Close',
              {duration: 1500});
          })
          .catch(result => console.log(result));

      })
      .then(r => this.onNoClick());

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  getDate(): string {
    let d = new Date();
    //Getting Date in consistent padding format
    let datestring = d.getFullYear() + "." +
      ("0" + (d.getMonth()+1)).slice(-2) + "." +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2) + ":" +
      ("0" + d.getSeconds()).slice(-2);

    return datestring;
  }
}

