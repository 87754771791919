import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Chameleon';

  onActivate(event){
    let scrollToTop = window.setInterval(()=> {
      let pos = window.pageYOffset;
      if(pos > 0) {
        window.scrollTo(0, pos - 20);
      }else {
        window.clearInterval(scrollToTop)
      }
    }, 16);
  }

}
