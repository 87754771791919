import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '../helpers/services';
import {User} from '../helpers/objects'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: User = this.authService.currentUserValue;
        const confirmed_user = this.authService.currentUserValue.cognitoUser['signInUserSession'];

        const url: string = route.url.join('');
    
        if (currentUser && confirmed_user != null) {
          if (url.indexOf('admin') > 0) {
            if (currentUser.isAdmin) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }      
        }
    
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
    
      canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
      }
    }
