import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthenticationService} from './authentication.service';
import {Role, User} from '../objects';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  currentUser: User;
  currentUserSubscription: Subscription;
  spinnerMsg: string = '';

  constructor(private authService: AuthenticationService) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = null;
      }
    });
  }

  get isTech() {
    return (this.currentUser['groups'] ? this.currentUser['groups'].indexOf(Role.Tech) >= 0 : false);
  }

  get isAdmin() {
    return (this.currentUser['groups'] ? this.currentUser['groups'].indexOf(Role.Admin) >= 0 : false);
  }

  get isSuper() {
    return (this.currentUser['groups'] ? this.currentUser['groups'].indexOf(Role.Super) >= 0 : false);
  }
}
